<template>
  <div class="columns is-marginless is-multiline">
    <div v-if="!isResellerClient" class="column is-12">
      <general-enquiry-cta />
    </div>
    <div class="column is-12">
      <div class="box">
        <tasks-table
          :participant-id="userId"
          :is-open="true"
          @createTask="
            $store.dispatch('tasks/openAddTaskModal', {
              userId
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenTasks",
  components: {
    "general-enquiry-cta": () => import("@shared/tasks/_createTaskCta"),
    "tasks-table": () => import("@shared/tasks/_tasksTable")
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    isResellerClient() {
      return this.$store.getters["user/isResellerUser"]();
    }
  }
};
</script>
